<template>
  <div>
    <el-card style="margin-bottom: 20px">
      <div>
        <div
          style="
            border-left: 5px solid #446cf3;
            padding-left: 10px;
            margin-bottom: 20px;
          "
        >
          采集数据
        </div>
        <div style="display: flex">
          <el-card style="width: 300px; margin-right: 20px">
            <div>
              <div style="margin-bottom: 20px">今日采集量(人)</div>
              <div>{{ todayCollected }}</div>
            </div>
          </el-card>
          <el-card style="width: 300px; margin-right: 20px">
            <div>
              <div style="margin-bottom: 20px">今日收款(元)</div>
              <div>{{ todayPaid }}</div>
            </div>
          </el-card>
          <el-card style="width: 300px; margin-right: 20px">
            <div>
              <div style="margin-bottom: 20px">今日待收款(元)</div>
              <div>{{ todayBePaid }}</div>
            </div>
          </el-card>
          <el-card style="width: 300px">
            <div>
              <div style="margin-bottom: 20px">本月总采集数(人)</div>
              <div>{{ thisMonthTotalCollected }}</div>
            </div>
          </el-card>
        </div>
      </div>
    </el-card>
    <el-card style="margin-bottom: 20px">
      <div>
        <div
          style="
            border-left: 5px solid #446cf3;
            padding-left: 10px;
            margin-bottom: 20px;
          "
        >
          任务管理
        </div>
        <div style="display: flex">
          <el-card style="width: 300px; margin-right: 20px">
            <div>
              <div style="margin-bottom: 20px">今日新增采集任务</div>
              <div>{{ todayNewTask }}</div>
            </div>
          </el-card>
          <el-card style="width: 300px; margin-right: 20px">
            <div>
              <div style="margin-bottom: 20px">预计采集量</div>
              <div>{{ estimateCollected }}</div>
            </div>
          </el-card>
          <el-card style="width: 300px">
            <div>
              <div style="margin-bottom: 20px">待审核任务</div>
              <div>{{ beReviewedTask }}</div>
            </div>
          </el-card>
        </div>
      </div>
    </el-card>
    <el-card>
      <div>
        <div
          style="
            border-left: 5px solid #446cf3;
            padding-left: 10px;
            margin-bottom: 20px;
          "
        >
          邮寄管理
        </div>
        <div style="display: flex">
          <div style="width: 200px; margin: 20px">
            <div style="text-align: center; margin-bottom: 10px">
              {{ collecting }}
            </div>
            <div style="text-align: center">正在采集</div>
          </div>
          <div style="width: 200px; margin: 20px">
            <div style="text-align: center; margin-bottom: 10px">
              {{ beMailed }}
            </div>
            <div style="text-align: center">待邮寄</div>
          </div>
          <div style="width: 200px; margin: 20px">
            <div style="text-align: center; margin-bottom: 10px">
              {{ beUploaded }}
            </div>
            <div style="text-align: center">待上传</div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { statistic } from "@/api/order";

export default {
  name: "Dashboard",
  data() {
    return {
      todayCollected: 0,
      todayPaid: 0.0,
      todayBePaid: 0.0,
      thisMonthTotalCollected: 0,
      todayNewTask: 0,
      estimateCollected: 0,
      beReviewedTask: 0,
      collecting: 0,
      beMailed: 0,
      beUploaded: 0,
    };
  },
  created() {
    this.query();
  },
  methods: {
    query() {
      statistic().then((resp) => {
        const data = resp.data;
        if (data.todayCollected == null) {
          this.todayCollected = 0;
        } else {
          this.todayCollected = data.todayCollected;
        }
        if (data.todayPaid == null) {
          this.todayPaid = 0.0;
        } else {
          this.todayPaid = data.todayPaid;
        }
        if (data.todayBePaid == null) {
          this.todayBePaid = 0.0;
        } else {
          this.todayBePaid = data.todayBePaid;
        }
        if (data.thisMonthTotalCollected == null) {
          this.thisMonthTotalCollected = 0;
        } else {
          this.thisMonthTotalCollected = data.thisMonthTotalCollected;
        }
        if (data.todayNewTask == null) {
          this.todayNewTask = 0;
        } else {
          this.todayNewTask = data.todayNewTask;
        }
        if (data.estimateCollected == null) {
          this.estimateCollected = 0;
        } else {
          this.estimateCollected = data.estimateCollected;
        }
        if (data.beReviewedTask == null) {
          this.beReviewedTask = 0;
        } else {
          this.beReviewedTask = data.beReviewedTask;
        }
        if (data.collecting == null) {
          this.collecting = 0;
        } else {
          this.collecting = data.collecting;
        }
        if (data.beMailed == null) {
          this.beMailed = 0;
        } else {
          this.beMailed = data.beMailed;
        }
        if (data.beUploaded == null) {
          this.beUploaded = 0;
        } else {
          this.beUploaded = data.beUploaded;
        }
      });
    },
  },
};
</script>

<style></style>
